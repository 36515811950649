import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})
export class PopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {}
  onNoClick(): void {
    this.dialogRef.close();
    // Navigate to the "Game" component
    this.router.navigate(['/Game']);
  }
}
