<div class="container">
  <!-- <div class="result">
    Hand-Eye Coordination Score: {{ data.handEyeCoordinationScore }} / 10
  </div> -->
  <div class="result">
    Average Reaction Time: {{ data.averageReactionTime }} ms (Score:
    {{ data.reactionTimeScore }} / 10)
  </div>
  <!-- <div class="result">
    Attention & Concentration Score: {{ data.attentionConcentrationScore }} / 10
  </div> -->
  <!-- <div class="result">
    Decision Making Score: {{ data.decisionMakingScore }} / 10
  </div> -->
  <button class="button" mat-dialog-close>Play Again</button>
</div>
