import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-game-results-dialog',
  templateUrl: './game-results-dialog.component.html',
  styleUrls: ['./game-results-dialog.component.css'],
})
export class GameResultsDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}
}
