<div class="container">
  <div class="timer">
    <div id="countdown">20</div>s
  </div>

  <div id="score">0</div>
  <div id="gameContainer">
    <div id="gameArea"></div>
  </div>

  <div id="startScreen">
    <button (click)="startGame('easy')">Easy</button>
    <button (click)="startGame('hard')">Hard</button>
  </div>
</div>
