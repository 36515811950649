import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-balloon-burst-challenge',
  templateUrl: './balloon-burst-challenge.component.html',
  styleUrls: ['./balloon-burst-challenge.component.css'],
})
export class BalloonBurstChallengeComponent implements OnInit {
  constructor(private dialog: MatDialog, private router: Router) {}
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

  Play() {
    // Open the dialog and store the reference
    this.dialog.open(PopupComponent);
    // Listen to the afterClosed event to perform actions after the dialog is closed
  }
}
