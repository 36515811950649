import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BalloonBurstChallengeComponent } from './balloon-burst-challenge/balloon-burst-challenge.component';
import { BalloonGameComponent } from './balloon-game/balloon-game.component';

const routes: Routes = [
  { path: '', component: BalloonBurstChallengeComponent },
  { path: 'Game', component: BalloonGameComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
