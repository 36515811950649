<h1>Instructions</h1>
<h3>Click on the balloons to pop them.</h3>
<hr class="separator" />
<h3>Each balloon you pop earns you points.</h3>
<hr class="separator" />
<h3>Keep popping balloons to increase your score.</h3>
<hr class="separator" />
<h3>
  Be quick! Balloons will appear randomly and disappear if not popped in time.
</h3>
<hr class="separator" />
<h3>The game ends when the timer runs out.</h3>
<button (click)="onNoClick()" class="close">Close</button>
